<template>
<div v-if="getAllowFullSite" class="main content-page about">

    <div class="content-sidebar vertical-image"></div>

    <div class="content-page-inner">
        <h1>First Landing, Cape Henry Virginia</h1>
        <h2>April 29, 1607</h2>
        <div class="quote-content">
            <div class="quote-marks">&ldquo;</div>
            <div>
                <p>We do hereby Dedicate this Land, and ourselves, to reach the People within these shores with the Gospel of Jesus Christ, and to raise up Godly generations after us, and with these generations take the Kingdom of God to all the earth. May this Covenant of Dedication remain to all generations, as long as this earth remains...</p>
                <p>&mdash; Robert Hunt, First Chaplain at Jamestown</p>
            </div>
            <div class="quote-marks">&rdquo;</div>
        </div>
        <router-link to="/contact"><button class="button-dark last-button">Ready to Connect?</button></router-link>
    </div>

</div>
</template>

<script>
export default {
    name: 'about',
    data() {
        return {
        }
    },
    created() {
        if (!this.getAllowFullSite) {
            this.$router.push('/coming-soon')
        }
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss" scoped>
.vertical-image {
    overflow: hidden;
    background-image: url("~@/assets/dedicated1.png");
    background-size: cover;
    background-position: center bottom;
}
.quote-content {
    margin: 2em 0;
    display: grid;
    grid-template-columns: 2.5em 1fr 2.5em;
    grid-gap: 1em;
    font-size: 1.1em;
}
</style>
